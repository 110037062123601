require('../css/app.scss');
const $ = require('jquery');
require('popper.js');
require('bootstrap');

// images
//----------------------------------------------------------------------------------------------------------------------
const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

$(function () {
    // sidebar
    //------------------------------------------------------------------------------------------------------------------
    $('.hamburger').on('click', function () {
        $(this).toggleClass('is-active');
        $('.sidebar').toggleClass('active');
    });

    // ajax modal
    //------------------------------------------------------------------------------------------------------------------
    const $spinner = $('<div/>').addClass('spinner-grow').addClass('text-primary').attr('role', 'status').append(
        $('<span/>').addClass('sr-only').text('Laden...')
    );
    const $ajaxModal = $('#ajaxModal');
    const $modalContent = $ajaxModal.find('.modal-content');

    $ajaxModal.on('show.bs.modal', function (e) {
        $modalContent.load(e.relatedTarget.href);
    }).on('hidden.bs.modal', function () {
        $modalContent.html($spinner);
    });

    // send forms in ajax modal via ajax
    $ajaxModal.on('submit', 'form', function (e) {
        e.preventDefault();

        let url = $(this).attr('action');
        let data = new FormData(this);

        $(this).find('button[type=submit]').addClass('loading').attr('disabled', 'disabled');

        $.post({
            url: url,
            data: data,
            processData: false,
            contentType: false,
            cache: false
        })
            .done(function (data) {
                if (data.done) {
                    $ajaxModal.modal('hide');
                    location.reload();
                } else {
                    $modalContent.html(data);
                }
            })
            .fail(function (data) {
                console.log("error while sending request");
                console.log(data);
            })
        ;
    });

    // tooltips
    //------------------------------------------------------------------------------------------------------------------
    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });

    // toasts
    //------------------------------------------------------------------------------------------------------------------
    $('.toast').toast({
        delay: 2000,
    }).toast('show');

    // user search
    //------------------------------------------------------------------------------------------------------------------
    $('#user_search_userGroup').on('change', function () {
        $(this).parents('form').trigger('submit');
    });
});

